
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Attributes</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-sm-12 col-md-4">
          <div class="input-group">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select v-model="defaultType" class="form-select flex-grow-1">
              <option value="" disabled selected>-- type --</option>
              <option v-for="(type, tIndex) in typeOptions"
                      :value="type.value" :key="tIndex" > {{type.label}} </option>
              <option value="all"> View all</option>
            </select>
            <b-button variant="primary" class="" @click.prevent="popupModalShow = true" type="button">
            <i class="ri ri-add-fill align-bottom"></i> <span class="d-none d-sm-inline"> Create </span></b-button>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <is-loading v-if="isLoading" />
      <div v-else>
        <div v-if="pageData.data.length" class="row">
          <div class="col-sm-6" v-for="(attribute, dIndex) of pageData.data" :key="dIndex">
            <attribute-card :item="attribute" >
              <template v-slot:actions>
                <button @click.prevent="showValues(attribute)" class="btn me-2 btn-sm btn-success">
                <i class="ri ri-edit-2-line align-bottom me-1"></i> View values </button>
                <button @click.prevent="initEdit(attribute)" class="btn me-2 btn-sm btn-primary">
                <i class="ri ri-edit-2-line align-bottom"></i> </button>
                <button  @click.prevent="deleteItem(attribute.id)" class="btn btn-sm btn-danger">
                <i class="ri ri-delete-bin-line align-bottom"></i></button>
              </template>
            </attribute-card>
          </div>
        </div>
        <div v-else class="fs-5 py-5 text-muted text-center">There are no items added!</div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="3" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
      <b-modal v-model="popupModalShow" hide-footer centered
        :title="editMode? 'Update attribute':'Add attribute'"
        title-class="fs-6" @hide="initializeAll()">
        <attribute-form v-if="popupModalShow" @closeMe="initializeAll()"
        :edit-mode="editMode" :edit-item="editItem" :type-options="typeOptions" />
      </b-modal>

      <b-modal v-model="popupModalValues" size="lg" hide-footer centered
        title="Attribute Values"
        title-class="" @hide="initializeAll()">
        <attribute-values v-if="popupModalValues"
        :showItem="editItem" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import AttributeForm from "@/components/forms/AttributeForm.vue"
import AttributeCard from "@/components/cards/AttributeCard.vue"
import AttributeValues from "./AttributeValues.vue"
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';

export default {
  name: "attribute-list",
  components:{
    AttributeForm,
    AttributeCard,
    AttributeValues,
    IsLoading
  },
  data() {
    return {
      isLoading: false,
      popupModalShow: false,
      popupModalValues: false,
      editMode: false,
      editItem: null,
      searchQuery: "",
      pageOptions: [10, 25, 50, 100],
      defaultType: 'all',
      typeOptions:[
        { value: 'storage',  label: 'Storage'},
        { value: 'carrier',  label: 'Carrier'},
        { value: 'condition',  label: 'Condition'},
        { value: 'color',  label: 'Color'}
      ],
    }
  },
  watch: {
    defaultType (val) {
      this.fetchItems()
    }
  },
  computed: {
    pageData(){
      return this.$store.state.attribute.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 25
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
  },
  methods: {
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.defaultType != 'all'){ payload.type = this.defaultType }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("attribute/fetchAttributes", payload)
      .then((_) => this.isLoading = false )
    },
    deleteItem(itemId) {
      this.confirmDelete({
        text: "You are about to delete an attribute",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/attributes/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit('attribute/DELETE_ITEM', itemId)
            }
          })
        }
      });
    },
    initEdit(item){
      this.editMode = true;
      this.editItem = {...item}
      this.popupModalShow = true  
    },
    showValues(item){
      this.editItem = {...item}
      this.popupModalValues = true  
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
      this.popupModalValues = false;
    },
  },
  created(){
    this.fetchItems()
  },
}

</script>

